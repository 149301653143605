import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: page introuvable" />
    <div className="flex items-center justify-center h-screen -mb-16">
      <div className="text-center">
        <h1>NOT FOUND</h1>
        <p>Cette page est introuvable.</p>
        <div className="mt-8 flex justify-center">
          <Link className="bg-primary py-2 px-4 rounded shadow-md" to="/">
            Retour à l'accueil
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
